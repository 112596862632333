import axios from "axios";
import React, { useState } from "react";
import styles from './home.module.scss';

import { Modal, ModalHeader, ModalBody} from "reactstrap";

// NOTICE
// Modal is brought in with it's own trigger, so import the component where you want the trigger to be.

const ModalComponent = (props: any) => {
  const [showPopup, setShowPopup] = useState(false);
  
  const {
    buttonText,
    title,
    actionButtonText,
    cancelButtonText,
    children,
    formData,
    className
  } = props;

  const toggle = () => setModal(!modal);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(formData);
    try {
      if(Object.keys(formData).length!=0) {
      console.log(formData);
      setModal(!modal);
      setShowPopup(true);

      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        window.startConfetti();
      }, 1000);
      
      await axios.post('https://api.supplychainq.com/sendEmail', formData)
      .then(response => {
              console.log(response);
              setModal(!modal);
              setTimeout(() => {
                // @ts-ignore: Unreachable code error
                window.stopConfetti();
              }, 3000)
            })
            .catch((error: any) => {
              console.error(error);
              setTimeout(() => {
                // @ts-ignore: Unreachable code error
                window.stopConfetti();
              }, 3000)
            });
          }
    } catch (error) {
      console.log(error);
    }
  };

  const [modal, setModal] = useState(false);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <div>
      <button className={`${styles['ui-button']} ${styles['ui-button-base']} ${styles['ui-button-primary']}`} type="submit" onClick={handleSubmit}>Join our waitlist</button>
      <Modal isOpen={modal} toggle={toggle} className={className} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        {/*<form onSubmit={alertshow}>*/}
          <ModalHeader className=" border-0" toggle={toggle} close={closeBtn}>
            <b>{title}</b>
          </ModalHeader>
          <ModalBody className="text-left border-0">
            <p className="modal-label">We are excited to have you on board and look forward to sharing the latest updates and news with you. We will keep you in the loop on all the exciting developments and upcoming events. Stay tuned!</p>
            {children}
          </ModalBody>
        {/*</form>*/}
      </Modal>
    </div>
  );
};

export default ModalComponent;