//import styles from './blog.module.scss';
import React, { useState } from "react";
import styles from "./home.module.scss";
import "../../assets/css/bootstrap.css";
import ben from "../../assets/img/ben.7656dd36.png";
import claire from "../../assets/img/claire.bfdbc161.png";
import iwan from "../../assets/img/iwan.70e1c572.png";
import judith from "../../assets/img/judith.8d0417f0.png";
import lori from "../../assets/img/lori.07099057.png";
import mali from "../../assets/img/mali.a8248c71.png";
import mi from "../../assets/img/mi.68a0f720.png";
import nim from "../../assets/img/nim.d6bbfb63.png";
import san from "../../assets/img/san.3b86301b.png";
import sanjid from "../../assets/img/sanjid.2d7a907f.png";
import steph from "../../assets/img/steph.68f47994.png";
import zak from "../../assets/img/zak.be2e6aeb.png";
import supplychain_q from "../../assets/img/supplychain_q.png";
import logo from "../../assets/img/logo.png";
import secure_and_robust from "../../assets/img/secure_and_robust.png";
import ai from "../../assets/img/ai.png";
import track_and_trace from "../../assets/img/track_and_trace.png";
import lorenzo from "../../assets/img/lorenzo.png";
import james from "../../assets/img/james.png";
import validator from "validator";
import ModalComponent from "../../components/Popup";

const Home = () => {
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});

  const handleInputChange = (event) => {
    if (event.target.value === "" || !validator.isEmail(event.target.value)) {
      setFormData({});
    } else {
      setFormData({
        email: event.target.value,
        subject: "From " + event.target.value,
      });
    }
  };

  const handleInputChangeTwo = (event) => {
    if (event.target.value === "" || !validator.isEmail(event.target.value)) {
      setFormData2({});
    } else {
      setFormData2({
        email: event.target.value,
        subject: "From " + event.target.value,
      });
    }
  };

  return (
    <div id="app">
      <div>
        <div
          className={`${styles["site-content"]} ${styles["bg-white"]} ${styles["overflow-hidden"]}`}
          data-testid="site-content"
        >
          <section
            id="6aqhu0f7fg"
            className={`${styles["pt-8"]} ${styles["overflow-hidden"]} ${styles["sm:pt-12"]} ${styles["lg:relative"]} ${styles["lg:py-48"]}`}
            sectionname="hero"
            sectioncomponent="Hero"
            sortorder="0"
          >
            <div
              className={`${styles["flex-right"]} ${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]} ${styles["lg:max-w-7xl"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:gap-24"]}`}
            >
              <div
                className={`${styles["flex"]} ${styles["items-center"]} ${styles["space-x-2"]}`}
              >
                <img
                  className={`${styles["w-auto"]} ${styles["h-8"]}`}
                  src={logo}
                  alt="SupplyChainQ logo"
                />
                <p
                  className={`${styles["font-sans"]} ${styles["text-xl"]} ${styles["font-bold"]} ${styles["text-gray-900"]}`}
                >
                  SupplyChainQ
                </p>
              </div>
              <div
                className={`${styles["mixo-badge"]} ${styles["items-center"]} ${styles["space-x-2"]}`}
              >
                <a
                  className={`${styles["mixo-badge"]}`}
                  href="http://prototype.supplychainq.com/"
                  target="_blank"
                  rel="noreferrer"
                  data-v-2391dbcb=""
                >
                  <b>Prototype</b>
                </a>
              </div>
            </div>
            <div
              className={`${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]} ${styles["lg:max-w-7xl"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:gap-24"]}`}
            >
              <div>
                <div className={`${styles["mt-14"]}`}>
                  <div className={`${styles["mt-6"]} ${styles["sm:max-w-xl"]}`}>
                    <h1
                      className={`${styles["text-4xl"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]} ${styles["sm:text-6xl"]} ${styles["md:text-7xl"]}`}
                    >
                      Transform your supply chain with{" "}
                      <span className={`${styles["text-primary"]}`}>AI</span>{" "}
                      and{" "}
                      <span className={`${styles["text-primary"]}`}>
                        Blockchain .
                      </span>
                    </h1>
                    <h2
                      className={`${styles["mt-6"]} ${styles["text-lg"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                    >
                      Automatically monitor, trace and securely transact your
                      supply chain with the power of blockchain. Leverage the AI
                      technology to optimize delivery and supply throughout the
                      system.
                    </h2>
                  </div>
                  <div className={`${styles["mt-10"]} ${styles["space-y-4"]}`}>
                    <ile-root id="ile-1" data-v-app="" hydrated="">
                      <form
                        className={`${styles["grid"]} ${styles["gap-2"]} ${styles["grid-cols-1"]} ${styles["sm:w-full"]} ${styles["sm:flex"]} ${styles["sm:items-center"]} ${styles["sm:flex-wrap"]} ${styles["mt-4"]} ${styles["sm:max-w-lg"]}`}
                      >
                        <label
                          htmlFor="hero-email"
                          className={`${styles["sr-only"]}`}
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                          className={`${styles["block"]} ${styles["w-full"]} ${styles["px-5"]} ${styles["py-3"]} ${styles["text-base"]} ${styles["text-gray-900"]} ${styles["placeholder-gray-500"]} ${styles["border"]} ${styles["border-gray-300"]} ${styles["rounded-md"]} ${styles["shadow-sm"]} ${styles["focus:border-primary"]} ${styles["focus:ring-primary"]} ${styles["focus-visible:ring-primary"]} ${styles["flex-1"]}`}
                          required={true}
                          placeholder="Enter your email..."
                          style={{ borderRadius: '5px' }}
                        ></input>
                        <div>
                          <ModalComponent
                            title="Thank you for subscribing to our newsletter!"
                            cancelButtonText="Cancel"
                            formData={formData}
                            actionButtonText="Submit"
                          />
                        </div>
                      </form>
                    </ile-root>
                    <script></script>
                  </div>
                  <div className={`${styles["mt-6"]}`}>
                    <div
                      className={`${styles["inline-flex"]} ${styles["items-center"]}`}
                    >
                      <img
                        src={lorenzo}
                        alt="Laurentiu Nae"
                        className={`${styles["object-cover"]} ${styles["inline-block"]} ${styles["mr-3"]} ${styles["border-2"]} ${styles["border-primary"]} ${styles["rounded-full"]} ${styles["sm:mr-2"]} ${styles["h-14"]} ${styles["w-14"]}`}
                      />
                      <div>
                        <p
                          className={`${styles["sm:pl-2.5"]} ${styles["text-base"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-800"]} ${styles["sm:text-lg"]}`}
                        >
                          {" "}
                          “The security and traceability of Blockchain
                          technology, combined with the power of
                          state-of-the-art AI systems, will revolutionize supply
                          chain management.”{" "}
                        </p>
                        <p
                          className={`${styles["sm:pl-2.5"]} ${styles["text-sm"]} ${styles["sm:text-base"]} ${styles["font-bold"]} ${styles["text-gray-500"]}`}
                        >
                          Laurentiu Nae
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles["sm:mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]}`}
            >
              <div
                className={`${styles["py-12"]} ${styles["sm:relative"]} ${styles["sm:mt-16"]} ${styles["sm:py-16"]} ${styles["lg:absolute"]} ${styles["lg:inset-y-0"]} ${styles["lg:right-0"]} ${styles["lg:w-1/2"]}`}
              >
                <div className={`${styles["hidden"]} ${styles["sm:block"]}`}>
                  <div
                    className={`${styles["absolute"]} ${styles["inset-y-0"]} ${styles["w-screen"]} ${styles["left-1/2"]} ${styles["bg-gray-50"]} ${styles["rounded-l-3xl"]} ${styles["lg:left-80"]} ${styles["lg:right-0"]} ${styles["lg:w-full"]}`}
                  ></div>
                  <svg
                    className={`${styles["absolute"]} ${styles["-mr-3"]} ${styles["top-8"]} ${styles["right-1/2"]} ${styles["lg:m-0"]} ${styles["lg:left-0"]}`}
                    width="404"
                    height="392"
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className={`${styles["text-gray-200"]}`}
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="392"
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    ></rect>
                  </svg>
                </div>
                <div
                  className={`${styles["relative"]} ${styles["pl-4"]} ${styles["-mr:20"]} ${styles["sm:-mr-32"]} ${styles["md:-mr-16"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-0"]} ${styles["lg:h-full"]} ${styles["lg:max-w-none"]} ${styles["lg:flex"]} ${styles["lg:items-center"]} ${styles["xl:pl-12"]}`}
                >
                  <img
                    className={`${styles["w-full"]} ${styles["rounded-l-3xl"]} ${styles["lg:w-auto"]} ${styles["2xl:h-full"]} ${styles["2xl:max-w-none"]}`}
                    src={supplychain_q}
                    alt="SupplyChainQ"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            className={`${styles["relative"]} ${styles["pt-16"]} ${styles["pb-32"]} ${styles["overflow-hidden"]} ${styles["bg-white"]} ${styles["space-y-24"]}`}
            sectionname="features"
            id="7pql4yc3mx"
            sectioncomponent="Features"
            sortorder="1"
            site-name="SupplyChainQ"
            site-id="JAndlR4ZzZ89dJoDCG2W"
            site-logo-url=""
          >
            <div>
              <div
                className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
              >
                <div
                  className={`${styles["lg:col-start-2"]} ${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
                >
                  <div>
                    <h2
                      className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                    >
                      AI-enabled technology for a sustainable future
                      <span className={`${styles["text-primary"]}`}>.</span>
                    </h2>
                    <p
                      className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                    >
                      Revolutionize your supply chain management with our AI
                      technology. Predict shipment completion, get valuable
                      insights on supply and demand, error correction, quality
                      control, capacity and planning, and item pricing. Optimize
                      your logistic routes, reduce waste, and transaction costs
                      with our AI sustainability features. Make informed
                      decisions and stay ahead of the game with our statistical
                      analysis and reporting AI-enabled tools.
                    </p>
                  </div>
                  <div></div>
                </div>
                <div
                  className={`${styles["lg:col-start-1"]} ${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
                >
                  <div
                    className={`${styles["pr-4"]} ${styles["-sm:ml-48"]} ${styles["sm:pr-6"]} ${styles["md:-ml-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                  >
                    <img
                      className={`${styles["lg:right-0"]} ${styles["w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                      src={ai}
                      alt="Secure and robust system"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
              >
                <div
                  className={`${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
                >
                  <div>
                    <h2
                      className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                    >
                      Track and trace
                      <span className={`${styles["text-primary"]}`}>.</span>
                    </h2>
                    <p
                      className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                    >
                      SupplyChainQ provides complete supply chain visibility
                      with real-time tracking and tracing capabilities. This
                      level of transparency is essential in today's global
                      market,where consumers demand more information about
                      products. With SupplyChainQ, you can build trust and
                      loyalty by providing your customers with the necessary
                      information to make informed decisions.
                    </p>
                  </div>
                  <div></div>
                </div>
                <div
                  className={`${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
                >
                  <div
                    className={`${styles["pl-4"]} ${styles["sm:-mr-48"]} ${styles["sm:-mr-6"]} ${styles["sm:pl-6"]} ${styles["md:-mr-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                  >
                    <img
                      className={`${styles["lg:left-0 w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                      src={track_and_trace}
                      alt="Track and trace"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
              >
                <div
                  className={`${styles["lg:col-start-2"]} ${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
                >
                  <div>
                    <h2
                      className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                    >
                      Secure and robust system
                      <span className={`${styles["text-primary"]}`}>.</span>
                    </h2>
                    <p
                      className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                    >
                      SupplyChainQ offers an unparalleled level of security and
                      transparency by ensuring that your data is completely
                      secure and transactions are transparent. With its
                      blockchain technology, SupplyChainQ provides the most
                      reliable system available to protect and monitor your
                      supply chain, enabling you to track your products at every
                      stage of the journey, from production to delivery.
                    </p>
                  </div>
                  <div></div>
                </div>
                <div
                  className={`${styles["lg:col-start-1"]} ${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
                >
                  <div
                    className={`${styles["pr-4"]} ${styles["-sm:ml-48"]} ${styles["sm:pr-6"]} ${styles["md:-ml-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                  >
                    <img
                      className={`${styles["lg:right-0"]} ${styles["w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                      src={secure_and_robust}
                      alt="Secure and robust system"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className={`${styles["py-12"]} ${styles["overflow-hidden"]} ${styles["bg-primary"]} ${styles["bg-opacity-80"]} ${styles["md:py-20"]}`}
            sectionname="testimonials"
            id="6gj4p3dm04"
            sectioncomponent="Testimonials"
            sortorder="2"
            site-name="SupplyChainQ"
            site-id="JAndlR4ZzZ89dJoDCG2W"
            site-logo-url=""
          >
            <div
              className={`${styles["relative"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["max-w-7xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]}`}
            >
              <svg
                className={`${styles["absolute"]} ${styles["transform"]} ${styles["top-full"]} ${styles["right-full"]} ${styles["translate-x-1/3"]} ${styles["-translate-y-1/4"]} ${styles["lg:translate-x-1/2"]} ${styles["xl:-translate-y-1/2"]} ${styles["rotate-3"]}`}
                width="404"
                height="404"
                fill="none"
                viewBox="0 0 404 404"
                role="img"
                aria-labelledby="svg-squares"
              >
                <title id="svg-squares">squares</title>
                <defs>
                  <pattern
                    id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className={`${styles["text-primary"]}`}
                      fill="currentColor"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="404"
                  fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
                ></rect>
              </svg>
              <div className={`${styles["relative"]}`}>
                <blockquote>
                  <div
                    className={`${styles["max-w-3xl"]} ${styles["mx-auto"]} ${styles["text-xl"]} ${styles["font-bold"]} ${styles["leading-7"]} ${styles["text-center"]} ${styles["text-white"]} ${styles["md:leading-10"]} ${styles["md:text-3xl"]} ${styles["text-shadow-sm"]}`}
                  >
                    <p>
                      {" "}
                      "By providing an immutable ledger of transactions,
                      blockchain can significantly improve traceability and
                      accountability in supply chain management."{" "}
                    </p>
                  </div>
                  <footer className={`${styles["mt-8"]}`}>
                    <div
                      className={`${styles["md:flex"]} ${styles["md:items-center"]} ${styles["md:justify-center"]}`}
                    >
                      <div className={`${styles["md:flex-shrink-0"]}`}>
                        <img
                          src={james}
                          className={`${styles["w-10"]} ${styles["h-10"]} ${styles["mx-auto"]} ${styles["border-2"]} ${styles["border-slate-200"]} ${styles["rounded-full"]} ${styles["shadow-sm"]} ${styles["object-cover"]}`}
                          alt="Testimonial"
                        />
                      </div>
                      <div
                        className={`${styles["mt-3"]} ${styles["text-center"]} ${styles["md:mt-0"]} ${styles["md:ml-3"]} ${styles["md:flex"]} ${styles["md:items-center"]} ${styles["text-shadow-sm"]}`}
                      >
                        <div
                          className={`${styles["text-lg"]} ${styles["font-medium"]} ${styles["text-white"]}`}
                        >
                          James Alabi
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </section>
          <section
            className={`${styles["relative"]} ${styles["py-12"]} ${styles["sm:py-20"]} ${styles["md:py-24"]} ${styles["lg:py-32"]}`}
            sectionname="cta"
            id="877sibv5tr"
            sectioncomponent="Cta"
            sortorder="4"
            site-name="SupplyChainQ"
            site-logo-url="" >
            <div
              aria-hidden="true"
              className={`${styles["block"]}`} >
              <div
                className={`${styles["absolute"]} ${styles["inset-y-0"]} ${styles["left-0"]} ${styles["w-1/2"]} ${styles["bg-gray-50"]} ${styles["rounded-r-3xl"]} ${styles["mb-12"]}`}>
                </div>
              <svg
                className={`${styles["absolute"]} ${styles["-ml-3"]} ${styles["top-8"]} ${styles["left-1/2"]}`}
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392" >
                <defs >
                  <pattern
                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                    
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className={`${styles["text-gray-200"]}`}
                      fill="currentColor"
                      
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                  
                ></rect>
              </svg>
            </div>
            <div
              className={`${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]}`}
              data-v-3a9a377e=""
            >
              <div
                className={`${styles["relative"]} ${styles["px-6"]} ${styles["py-10"]} ${styles["overflow-hidden"]} ${styles["bg-white"]} ${styles["border-primary"]} ${styles["border-2"]} ${styles["shadow-xl"]} ${styles["rounded-2xl"]} ${styles["sm:px-12"]} ${styles["sm:py-20"]}`}
                
              >
                <div
                  aria-hidden="true"
                  className={`${styles["absolute"]} ${styles["inset-0"]} ${styles["-mt-72"]} ${styles["sm:-mt-32"]} ${styles["md:mt-0"]}`}
                  
                >
                  <svg
                    className={`${styles["absolute"]} ${styles["inset-0"]} ${styles["w-full"]} ${styles["h-full"]}`}
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                    
                  >
                    <path
                      className={`${styles["text-primary/5"]}`}
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                      
                    ></path>
                    <path
                      className={`${styles["text-primary/10"]}`}
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                      
                    ></path>
                  </svg>
                </div>
                <div className={`${styles["relative"]}`} >
                  <div
                    className={`${styles["flex"]} ${styles["flex-wrap"]} ${styles["justify-center"]} ${styles["w-full"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]}`}
                    
                  >
                    <img
                      src={ben}
                      alt="User ben"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={claire}
                      alt="User claire"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={iwan}
                      alt="User iwan"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={lori}
                      alt="User lori"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={mali}
                      alt="User mali"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={mi}
                      alt="User mi"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={nim}
                      alt="User nim"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={san}
                      alt="User san"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={sanjid}
                      alt="User sanjid"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={steph}
                      alt="User steph"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={zak}
                      alt="User zak"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                    <img
                      src={judith}
                      alt="User judith"
                      className={`${styles["user-avatar"]}`}
                      data-v-3a9a377e=""
                    />
                  </div>
                  <div
                    className={`${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:text-center"]}`}
                    
                  >
                    <h2
                      className={`${styles["text-3xl"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]} ${styles["sm:text-4xl"]} ${styles["md:text-5xl"]} ${styles["md:leading-tight"]} ${styles["md:max-w-4xl"]} ${styles["md:mx-auto"]}`} >
                      Join our community of fans that love SupplyChainQ
                      <span
                        className={`${styles["text-primary"]}`} >
                        .
                      </span>
                    </h2>
                  </div>
                  <div
                    className={`${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-lg"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]}`}
                    
                  ></div>
                  <ile-root id="ile-3" data-v-app="" hydrated="">
                    <form
                      className={`${styles["grid"]} ${styles["gap-2"]} ${styles["grid-cols-1"]} ${styles["sm:w-full"]} ${styles["sm:flex"]} ${styles["sm:items-center"]} ${styles["sm:flex-wrap"]} ${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-lg"]}`} >
                      <label
                        htmlFor="cta-email"
                        className={`${styles["sr-only"]}`} >
                        Email address
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleInputChangeTwo}
                        className={`${styles["block"]} ${styles["w-full"]} ${styles["px-5"]} ${styles["py-3"]} ${styles["text-base"]} ${styles["text-gray-900"]} ${styles["placeholder-gray-500"]} ${styles["border"]} ${styles["border-gray-300"]} ${styles["rounded-md"]} ${styles["shadow-sm"]} ${styles["focus:border-primary"]} ${styles["focus:ring-primary"]} ${styles["focus-visible:ring-primary"]} ${styles["flex-1"]}`}
                        required={true}
                        placeholder="Enter your email..."
                      ></input>
                      <div>
                        <ModalComponent
                          title="🎉🎉Thank you for subscribing to our newsletter!🎉🎉"
                          cancelButtonText="Cancel"
                          formData={formData2}
                          actionButtonText="Submit"
                        />
                      </div>
                    </form>
                  </ile-root>
                </div>
              </div>
            </div>
          </section>
          <section
            className={`${styles["px-4"]} ${styles["py-12"]} ${styles["sm:py-16"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]} ${styles["md:py-24"]} ${styles["bg-primary/10"]}`}
            sectionname="note"
            id="gldf5pnog0"
            sectioncomponent="Note"
            sortorder="5"
            site-name="SupplyChainQ"
            site-id="JAndlR4ZzZ89dJoDCG2W"
            site-logo-url="">
            <h2
              className={`${styles["mb-12"]} ${styles["text-4xl"]} ${styles["font-bold"]} ${styles["text-center"]}`} >
              A note from the founder
              <span className={`${styles["text-primary"]}`} >
                .
              </span>
            </h2>
            <div
              className={`${styles["max-w-2xl"]} ${styles["mx-auto"]} ${styles["overflow-hidden"]} ${styles["bg-white"]} ${styles["rounded-lg"]} ${styles["shadow"]}`} >
              <div
                className={`${styles["px-4"]} ${styles["py-5"]} ${styles["space-y-4"]} ${styles["sm:p-6"]}`} >
                <div className={`${styles["note-text"]}`}>
                  We are a team passionate about revolutionizing the industry
                  supply chain through blockchain technology. Our goal is to
                  create a transparent, secure and efficient supply chain,
                  improving the experience for all stakeholders involved. Join
                  us on this journey and be a part of the change that will shape
                  the future of the industry.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ile-root id="ile-2" data-v-app="" hydrated=""></ile-root>
      {/* <script></script> */}
    </div>
  );
};
export default Home;
